/**
 * Created by Aceh on 2017/01/01.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Tips } from 'components';
import { getFontSize, deleteProps, addTipsByLen, buildShowTip } from 'utils';
import Popover from 'components/utilcomponents/tips/Tooltip/popover';
import reactComposition from 'react-composition';
import { HOVER_TIPS_PARAM } from 'constants';
import { prefixCls } from './index.scss';

export default class BaseInput extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {
      defaultValue: prop.defaultValue || prop.value || '',
    };
    this.continuousL = 0;
    this.FontSize = 18;
  }

  static propTypes = {
    style: PropTypes.object,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    placeholder: PropTypes.string,
    readOnly: PropTypes.any,
    disabled: PropTypes.any,
    classname: PropTypes.string,
    type: PropTypes.string, // input 类型 ： text passwordd, radio, checkbox
    valiType: PropTypes.string, // 字段校验样式类型
    isActive: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onKeyDown: PropTypes.func,
    tips: PropTypes.string, // input上是否有  tips
    trigger: PropTypes.string, // tips 触发方式 click mouseover
    autoFocus: PropTypes.bool,
    onFocusZoom: PropTypes.bool, // 获取焦点时 字体自动放大
    autoTrim: PropTypes.bool, // 自动去除空格
    onDoubleClick: PropTypes.func, // 双击事件
    isFocusSelect: PropTypes.bool, // 获取加点自动选中
    ignoreInnerState: PropTypes.bool, // 内部状态
    tipsclassname: PropTypes.string, // 提示样式
    tipsIsDom: PropTypes.bool,
    tipsType: PropTypes.string, // 提示样式类型
    showTitleTips: PropTypes.bool,
  };
  static defaultProps = {
    readOnly: false,
    disabled: false,
    autoFocus: false,
    onFocusZoom: false,
    autoTrim: true,
    isFocusSelect: true,
    ignoreInnerState: false,
    tipsIsDom: false,
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillUpdate(nextProps, nextState) {
    let { defaultValue } = nextState;
    if (this.props.ignoreInnerState && nextProps.defaultValue !== this.props.defaultValue) {
      defaultValue = nextProps.defaultValue;
    } else if (
      (nextProps.defaultValue !== defaultValue && nextProps.defaultValue !== this.props.defaultValue) || // 。。。
      (nextProps.defaultValue !== defaultValue && nextState.defaultValue === this.state.defaultValue) || // state 没变 props 变了
      nextProps.value !== this.props.value
    ) {
      defaultValue = nextProps.defaultValue;
    }
    // eslint-disable-next-line react/no-will-update-set-state
    this.setState({ defaultValue });
  }

  componentDidMount() {
    this.props.onFocusZoom && (this.fontSize = getFontSize(this._input || document.body, true) || 18);
  }

  getValue = () => (this.props.autoTrim ? this._input.value.trim() : this._input.value); // this.state.defaultValue
  setValue = (val, cb) => this.setState({ defaultValue: val }, () => cb && cb());
  getDefaultValue = () => (this.props.autoTrim ? this.props.defaultValue.trim() : this.props.defaultValue);
  handleResetState = () => {
    this.setState({ defaultValue: '' });
  };
  focus = () => {
    this._input?.focus();
  };
  blur = () => {
    this._input?.blur();
  };
  handleChange = e => {
    e.persist();
    const { value } = e.target;
    const maxContinuousL = this.props.maxLength || 200;
    this.continuousL = value.length;
    if (this.continuousL > maxContinuousL) return;
    if (this.props.ignoreInnerState) {
      this.props.onChange && this.props.onChange(e);
      return;
    }
    if (e.reactComposition.composition === false) {
      this.setState({ defaultValue: value }, () => this.props.onChange && this.props.onChange(e));
      // this.props.onChange(e)
    }
    this.setState({ defaultValue: value });
  };
  handleKeyDown = e => {
    this.onKeyDown && this.onKeyDown(e);
    this.props.onKeyDown && this.props.onKeyDown(e);
  };
  onFocus = e => {
    this.props.isFocusSelect && e.target.select();
    if (this.props.onFocusZoom) {
      this.setState({ fontSize: this.fontSize + 4 });
    }
    this.props.onFocus && this.props.onFocus(e);
  };
  onBlur = e => {
    if (this.props.onFocusZoom) {
      this.setState({ fontSize: this.fontSize - 4 });
    }
    this.props.onBlur && this.props.onBlur(e);
  };
  // zgz 双击事件
  handleDblclick = e => {
    this.props.onDoubleClick &&
      this.props.onDoubleClick(e, this.props.autoTrim ? e.target.value.trim() : e.target.value.value);
  };
  handleMouseEnter = e => {
    if (
      !this.props.tips &&
      this.props.showTitleTips &&
      (this.props.disabled || this.props.readOnly) &&
      addTipsByLen(e.target, this.spanWrap)
    ) {
      buildShowTip({ content: e.target.value, ...HOVER_TIPS_PARAM })(e);
      this.titleTipsShowed = true;
    }
    this.props.onMouseEnter && this.props.onMouseEnter(e);
  };
  handleMouseLeave = e => {
    if (this.titleTipsShowed) {
      this.titleTipsShowed = false;
      Popover.hide();
    }
    this.props.onMouseLeave && this.props.onMouseLeave(e);
  };

  render() {
    const {
      classname,
      readOnly,
      type,
      valiType,
      isActive,
      tips,
      autoFocus,
      style = {},
      trigger,
      tipsIsDom,
      tipsType,
      ...rest
    } = this.props;
    const classes = classnames({
      [prefixCls]: true,
      [classname]: classname,
      [`${prefixCls}--${valiType}`]: valiType,
      [`${prefixCls}--readonly`]: readOnly,
      [`${prefixCls}--disabled`]: this.props.disabled,
      [`${prefixCls}--active`]: isActive,
    });
    const inputObj = (
      <div className="baseInput__w" ref={r => (this.spanWrap = r)}>
        <input
          {...deleteProps(
            rest,
            'children',
            'getPaths',
            'onChange',
            'onKeyDown',
            'onFocus',
            'isUpdateComponentResetValue',
            'isFocusSelect',
            'tipsIsDom',
            'defaultValue',
            'value',
            'onFocusZoom',
            'autoTrim',
            'validate',
            'handleSelected',
            'onDoubleClick',
            'handleHideCallback',
            'ignoreInnerState',
          )}
          {...reactComposition({ onChange: this.handleChange })}
          onKeyDown={this.handleKeyDown}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onDoubleClick={this.handleDblclick}
          disabled={this.props.disabled}
          readOnly={readOnly || this.props.disabled}
          ref={input => (this._input = input)}
          value={this.state.defaultValue === undefined ? '' : this.state.defaultValue}
          className={classes}
          type={type}
          autoFocus={autoFocus}
          style={{ ...style, fontSize: this.state.fontSize || style.fontSize }}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        />
      </div>
    );
    let content = inputObj;
    if (tips !== undefined && tips !== '') {
      content = (
        <Tips title={tips} className={this.props.tipsclassname} isDom={tipsIsDom} trigger={trigger} type={tipsType}>
          {inputObj}
        </Tips>
      );
    }
    if (type === 'password') {
      const sl = { position: 'absolute', top: '-99999px' };
      return (
        <div style={{ display: 'inline-block' }}>
          <input style={sl} type="text" tabIndex="-1" />
          <input style={sl} type="password" tabIndex="-1" />
          {content}
        </div>
      );
    }
    return content;
  }
}
