import { genImageURL, showInfo, getFileUrl, getViewFileType } from 'utils';
import _ from 'lodash';
import Draggable from 'react-draggable';
import { INFO } from 'constants';
import { FILE_TYPE_THUMBNAIL } from '@/constants/fileType.js';
import React from 'react';

export const downloadViewImage = props => {
  const { name, src } = props;
  const a = document.createElement('a');
  let url = src;
  // 判断是否是通过接口地址获取的 oss 图片，在接口上添加 download 参数，告诉后端需要下载，
  // 接口302 重定向的地址上加上response-content-disposition=attachment;filename=name 告知浏览器需要直接下载
  if (url.includes('/api/Basic/Image/getImage')) {
    url = `${url}&download=1&filename=${name}`;
  }
  a.href = url;
  a.download = name; // 获取文件名
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getFileInfo = item => {
  const fileType = getViewFileType(item);
  if (fileType === 'image') {
    const src = item.src || genImageURL(item);
    return {
      src,
      thumbnail: src,
      fileType,
    };
  } else {
    const src = item.src || getFileUrl(item);
    return {
      src,
      thumbnail: FILE_TYPE_THUMBNAIL[fileType],
      fileType,
    };
  }
};

export const formatFileItem = item => {
  const { src, thumbnail, fileType } = getFileInfo(item);
  return {
    ...item,
    src,
    fileType,
    original: item.original || src,
    thumbnail: item.thumbnail || thumbnail || src,
  };
};

export const getFiles = (props, fileList = []) => {
  let defaultIndex = 0;
  const { name, src: currentSrc } = props;

  const file_list = _.isObject(fileList) ? Object.values(fileList) : fileList;

  const files = file_list.map(formatFileItem);

  const index = files.findIndex(item => (name && item.name === name) || (currentSrc && item.src === currentSrc));
  if (index >= 0) {
    defaultIndex = index;
  } else {
    // 独立预览
    files.unshift(formatFileItem(props));
  }
  return {
    files,
    defaultIndex,
  };
};

export const defaultProps = {
  visible: true,
  modalProps: {
    title: <div style={{ textAlign: 'left' }}> 图片/文件预览 </div>,
    modalRender: modal => <Draggable handle=".gui-modal-header">{modal}</Draggable>,
    style: {
      width: '750px',
      padding: ' 0 24px 16px',
    },
    footer: null,
    closable: true,
    simple: true,
    autoFocus: true,
    icon: null,
  },
  galleryProps: {
    fitHeight: '450px',
    navPosition: 'thumbnail',
    thumbnailStyle: { width: 100, maxHeight: 100 },
    onKeySlide: (_e, direction, can) => {
      if (!can) {
        showInfo(INFO, direction === 'left' ? '已是第一张' : '已是最后一张');
      }
    },
  },
};

export const getGalleryConfig = props => {
  const { galleryProps } = props;
  const localGalleryProp = _.assign(defaultProps.galleryProps, galleryProps);

  return {
    ...localGalleryProp,
  };
};

export const getSpecialGalleryGroup = data => {
  const defaultInfo = data?.[0] || {};
  const { files } = getFiles(defaultInfo || {}, data);
  const newFiles = files?.map(file => {
    const newFile = {
      ...file,
    };
    if (!['image', 'pdf'].includes(file.fileType)) {
      delete newFile.fileType;
    }
    return newFile;
  });
  return newFiles;
};

export const getSpecialGalleryItem = item => {
  const newFile = formatFileItem(item);
  if (!['image', 'pdf'].includes(newFile.fileType)) {
    delete newFile.fileType;
  }

  return newFile;
};

export const customDownload = (info, fileList) => {
  const { name, src } = info;
  const findItem = fileList.find(item => (name && item.name === name) || (src && item.src === src));
  const downLoadFile = { src, name: findItem?.showName || name };
  downloadViewImage(downLoadFile);
};
