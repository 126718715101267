import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Gallery, Modal } from '@gui/web-react';
import { confirm } from 'utils';
import { getFiles, getGalleryConfig } from './utils';
import { Load } from 'components';
import { INFO } from 'constants';
import { IconDelete } from '@gui/web-react/icon';
import _ from 'lodash';

export default class EnhanceGallery extends PureComponent {
  static propTypes = {
    image: PropTypes.object,
    imgList: PropTypes.array,
    that: PropTypes.object,
    onSlide: PropTypes.func, // 切换图片的回调
    deleteSucceed: PropTypes.func, // 给外部提供删除成功后的回调，用于标识是否有删除图片
    onDeleteSucceed: PropTypes.func, // 删除成功后，关闭弹窗
    deleteImageName: PropTypes.string,
  };

  static defaultProps = {
    deleteImageName: '删除',
  };

  constructor(props) {
    super(props);
    const {
      image,
      imgList,
      deleteUrl,
      customDeleteFun,
      deleteSucceed,
      onDeleteSucceed,
      deleteImageName,
      galleryProps,
    } = props;
    const { onSlide } = galleryProps || {};
    const cloneImgList = _.cloneDeep(imgList);
    const self = this;
    this.deleteAction = [
      {
        key: 'DeleteImage',
        content: <IconDelete />,
        name: deleteImageName,
        onClick: async () => {
          const index = self.gallery?.getCurrentIndex?.();
          const cur = cloneImgList[index];
          const len = cloneImgList.length;
          if (!(await confirm(INFO, '您确定要删除照片吗？', {}, '确认', null, null, null, { isModal: true })))
            return false;

          // TODO: 删除图片公共方法，直接提供接口
          // if (deleteUrl) {}
          if (customDeleteFun) {
            self.setState({
              loading: true,
            });
            const result = await customDeleteFun(cur, index);
            self.setState({
              loading: false,
            });
            if (!result) return;
          }

          // 触发已经成功删除了图片
          deleteSucceed?.();

          // 删除时，只有一张照片，删除成功后直接关闭预览弹窗
          if (len === 1) {
            Modal.destroyAll();
            onDeleteSucceed?.();
            return;
          }

          // 更新开始索引
          const startIndex = index === len - 1 ? len - 2 : index;
          // 删除后更新数据
          cloneImgList.splice(index, 1);
          self.updateImgList({
            imgList: cloneImgList,
            image: cloneImgList[startIndex],
            callback: () => {
              // 删除后执行切换图片回调
              onSlide?.(startIndex, cloneImgList);
            },
          });
        },
      },
    ];
    this.state = {
      image,
      imgList,
      loading: false,
    };
  }
  updateImgList({ imgList, image, callback }) {
    this.setState(
      {
        imgList,
        image,
      },
      () => {
        callback?.();
      },
    );
  }
  render() {
    const { image, imgList, loading } = this.state;
    const { files, defaultIndex } = getFiles(image, imgList);
    let localGalleryProp = getGalleryConfig(this.props);
    localGalleryProp = {
      ...localGalleryProp,
      actions: this.deleteAction,
    };
    return (
      <Load spinning={loading}>
        <Gallery {...localGalleryProp} ref={r => (this.gallery = r)} items={files} startIndex={defaultIndex} />
      </Load>
    );
  }
}
