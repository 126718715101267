import React, { useEffect, useState, forwardRef } from 'react';
// import PropTypes from 'prop-types';
import { FLOAT } from 'constants';
import { PureInput } from 'components';
import { prefixCls } from './index.scss';
import { isEmptyValue } from 'utils';

const INPUT_TYPE = {
  min: 'min', // 最小值
  max: 'max', // 最大值
};

const DigitRange = forwardRef(
  ({
    value = [],
    onChange,
    separator = '~',
    placeholder = ['最小值', '最大值'],
    precision = 2,
    min = 0,
    max = 99999999.99,
    suffix,
    pattern = FLOAT,
    inputProps,
    required,
    disabled,
    customValidity,
  }) => {
    const [rangeVal, setRangeVal] = useState([0, 0]);
    const [minPlaceholder, maxPlaceholder] = placeholder;

    useEffect(() => {
      setRangeVal(value);
    }, [value]);

    const validity = async type => {
      if (customValidity) {
        return await customValidity(rangeVal, type);
      }
      const [minVal, maxVal] = rangeVal;
      const val = type === 'min' ? minVal : maxVal;
      const otherVal = type === 'min' ? maxVal : minVal;
      if (isEmptyValue(val) && required) return '不能为空';
      if (+val > +max || +val < +min) return `范围：${min} ${separator} ${max}`;
      if (+minVal >= +maxVal && !isEmptyValue(val) && !isEmptyValue(otherVal)) return '起始值不能大于等于结束值';
      if (isEmptyValue(val) && !isEmptyValue(otherVal)) return '不能为空';
    };

    const handleChangeValue = (e, type) => {
      const result = e.target.value;
      const [minVal, maxVal] = rangeVal;
      if (type === INPUT_TYPE.min) {
        onChange?.([result, maxVal]);
      } else if (type === INPUT_TYPE.max) {
        onChange?.([minVal, result]);
      }
    };

    const renderInputNumber = type => {
      const [minVal, maxVal] = rangeVal;
      let inputValue = type === INPUT_TYPE.min ? minVal : maxVal;
      if (inputValue === 0) {
        inputValue = '0';
      }
      const inputPlaceholder = type === INPUT_TYPE.min ? minPlaceholder : maxPlaceholder;

      return (
        <PureInput
          value={inputValue}
          precision={precision}
          pattern={pattern}
          placeholder={inputPlaceholder}
          customValidity={() => validity(type)}
          onChange={e => handleChangeValue(e, type)}
          style={{ width: '130px', textAlign: 'center' }}
          disabled={disabled}
          {...inputProps}
        />
      );
    };

    return (
      <div className={`${prefixCls}`}>
        {renderInputNumber(INPUT_TYPE.min)}
        <span className="to">{separator}</span>
        {renderInputNumber(INPUT_TYPE.max)}
        {suffix && <span className="suffix">{suffix}</span>}
      </div>
    );
  },
);
export default DigitRange;
