/**
 * Created by jany on 2017/11/16.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { Cell as BaseCell } from 'fixed-data-table-2';
import { Icon } from 'components';
import { isset, typeIs } from 'utils';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';
import { isShowCopyButton } from '../helper/action';

const prefixCls = 'fn-table_copy_cell';
const DASHBORDER = (
  <div className="dash_border">
    <span className="dashing">
      <i />
    </span>
    <span className="dashing">
      <i />
    </span>
    <span className="dashing">
      <i />
    </span>
    <span className="dashing">
      <i />
    </span>
  </div>
);
export default class Cell extends BaseCell {
  constructor(prop) {
    super(prop);
    this.timer = null;
    this.state = { render: false };
  }
  static propTypes = {
    copyerValue: PropTypes.any,
    tableKey: PropTypes.string,
    align: PropTypes.string,
    popStyle: PropTypes.object,
    shouldWrap: PropTypes.bool,
    type: PropTypes.string,
  };
  onMouseEnter = (e, ...args) => {
    const { rowIndex, columnKey } = this.props;
    const rc = `${rowIndex}${columnKey}`;
    let CS = this.coyperStatus;
    this.props.onMouseEnter && this.props.onMouseEnter(...args);
    this.timer && clearTimeout(this.timer); // TODO 侧拉打开的listpage触发了两次onMouseEnter
    this.timer = setTimeout(() => {
      CS ? (CS.isHove = { [rc]: true }) : (CS = { isHove: { [rc]: true } });
      this.refresher();
      !isset(this.coyperStatus, `rightMenuShow.[${rc}]`) && this.setHideFocus(e);
    }, 50);
  };
  onMouseLeave = (...args) => {
    const { rowIndex, columnKey } = this.props;
    const rc = `${rowIndex}${columnKey}`;
    const CS = this.coyperStatus;
    this.props.onMouseLeave && this.props.onMouseLeave(...args);
    this.timer && clearTimeout(this.timer);
    const isRightMenu = isset(this.coyperStatus, `rightMenuShow.[${rc}]`);
    const isCopyed = isset(this.coyperStatus, `isCopyed.[${rc}]`);
    if (CS) {
      CS.isHove = { [rc]: false };
      CS.refresher && !isRightMenu && !isCopyed && delete CS.refresher[rc];
    } else {
      this.coyperStatus = { isHove: { [rc]: false } };
    }
    this.refresher();
  };
  onContextMenu = (...args) => {
    const { rowIndex, columnKey } = this.props;
    const rc = `${rowIndex}${columnKey}`;
    const CS = this.coyperStatus;
    if (CS) {
      CS.rightMenuShow = { [rc]: true };
      CS.refresher ? (CS.refresher[rc] = this.refresher) : (CS.refresher = { [rc]: this.refresher });
    } else {
      this.coyperStatus = { rightMenuShow: { [rc]: true } };
      this.coyperStatus = { refresher: { [rc]: this.refresher } };
    }
    this.refresherAll('rightMenuShow');
    this.setHideFocus();
    this.props.onContextMenu && this.props.onContextMenu(...args);
  };
  copyClick = e => {
    e && e.stopPropagation();
    const { rowIndex, columnKey } = this.props;
    const rc = `${rowIndex}${columnKey}`;
    const CS = this.coyperStatus;
    // 有this.props.valueWithoutIcon 则取this.props.valueWithoutIcon 此时this.props.copyerValue不是字符串
    copy(this.props.valueWithoutIcon ? this.props.valueWithoutIcon : this.props.copyerValue);
    if (CS) {
      CS.isCopyed = { [rc]: true };
      CS.refresher ? (CS.refresher[rc] = this.refresher) : (CS.refresher = { [rc]: this.refresher });
    } else {
      this.coyperStatus = { isCopyed: { [rc]: true } };
      this.coyperStatus = { refresher: { [rc]: this.refresher } };
    }
    this.refresherAll('isCopyed');
  };
  onGlobalCtrlC = e => {
    if ((e.ctrlKey || e.metaKey) && e.keyCode === 67) {
      this.copyClick(); // ctrl + c 复制
    }
  };
  onClick = (...args) => {
    this.props.onClick && this.props.onClick(...args);
  };
  setHideFocus = () => {
    const activeParent = document.activeElement.parentElement;
    // 焦点在下拉时 不获取焦点
    if (
      activeParent &&
      activeParent.className &&
      activeParent.className.includes('fn-input-pure-wrap fn-select active')
    )
      return;
    this.coyperStatus.tableHideInput && this.coyperStatus.tableHideInput.focus();
    this.coyperStatus.tableHideInput && (this.coyperStatus.tableHideInput.onKeyDown = this.onGlobalCtrlC);
  };
  refresher = refreshKey => {
    this.setState({ render: !this.state.render }, refreshKey ? () => this.clearOthers(refreshKey) : undefined);
  };
  refresherAll = refreshKey => {
    const { rowIndex, columnKey } = this.props;
    const rc = `${rowIndex}${columnKey}`;
    if (isset(this.coyperStatus, `${refreshKey}.[${rc}]`)) {
      Object.entries(isset(this.coyperStatus, 'refresher', {})).forEach(([, r]) => r && r());
    }
  };
  getCover = prop => {
    const { copyerValue, rowIndex, columnKey, tableKey, popStyle = {}, cellStyle, shouldWrap, valueWithoutIcon } = prop;
    const rc = `${rowIndex}${columnKey}`;
    const c = {};
    if (copyerValue !== undefined) {
      !window.coyperStatus[tableKey] && (window.coyperStatus[tableKey] = {});
      this.coyperStatus = window.coyperStatus[tableKey]; // 初始化复制信息 rightMenuShow，isHove， isCopyed，tableHideInput
      c.otherProps = {
        onContextMenu: this.onContextMenu,
        onMouseEnter: this.onMouseEnter,
        onMouseLeave: this.onMouseLeave,
      };
      const isRightMenu = isset(this.coyperStatus, `rightMenuShow.[${rc}]`);
      const isHove = isset(this.coyperStatus, `isHove.[${rc}]`);
      const isCopyed = isset(this.coyperStatus, `isCopyed.[${rc}]`);
      if (
        !(isRightMenu || isHove || isCopyed) ||
        copyerValue === '' ||
        copyerValue === null ||
        !copyerValue.toString().length
      )
        return c;
      const coverCls = classnames({
        [prefixCls]: true,
        [`${prefixCls}_hove`]: isHove,
        [`${prefixCls}_dash_border`]: isCopyed,
        [`${prefixCls}_solid_border`]: isRightMenu,
      });
      const textAlign = isHove ? 'left' : popStyle.align;
      delete popStyle.height;
      let title = copyerValue;
      let copyerContent;
      const isShowCopy = isShowCopyButton(this.props);

      // 兼容附件与文件信息，用于Tips提示
      const getTitle = (x, i) => {
        if (typeIs(x, 'object')) {
          if (x.show_val) return x.show_val;
          if (x.title) return x.title;
          if (x.showName) return x.showName;
          if (x.showname) return x.showname;
          if (x.name) return x.name;
          return `[${i + 1}]`;
        }
        return x;
      };

      if (Array.isArray(copyerValue)) {
        title = [];
        copyerContent = copyerValue.map((x, i) => {
          // eslint-disable-next-line no-nested-ternary
          const linkStr = typeIs(x, 'object') ? (x.show_val ? x.show_val : `[${i + 1}]`) : x;
          title.push(getTitle(x, i));
          return (
            <span
              key={i}
              className={classnames('fn-input fn-input--readonly', { should_wrap: shouldWrap })}
              onClick={e => this.onClick(e, x)}
              // style={{ marginRight: 6, height: height - (isRightMenu ? 8 : 6), ...popStyle, textAlign }}
              style={{ marginRight: 6, ...popStyle, textAlign: !isShowCopy ? 'center' : textAlign }}
            >
              {linkStr}
            </span>
          );
        });
        title = title.join(' ');
      } else {
        copyerContent = (
          <span
            onClick={this.onClick}
            className={classnames('fn-input fn-input--readonly', { should_wrap: shouldWrap })}
            style={{ ...popStyle, textAlign: !isShowCopy ? 'center' : textAlign }}
          >
            {copyerValue}
          </span>
        );
        // 鼠标移上去的title展示
        if (valueWithoutIcon) {
          title = valueWithoutIcon;
        }
      }
      c.cover = (
        <div style={cellStyle} className={coverCls} data-type={this.props.type}>
          {DASHBORDER}
          <div className="fn-input-wrap fn-table_copy_cell_input_icon">
            <div className="baseInput__w" style={{ textAlign: !isShowCopy ? 'center' : textAlign }} title={title}>
              {copyerContent}
            </div>
            {isShowCopy ? <Icon iconType="icon-copy1" onClick={this.copyClick} /> : null}
          </div>
        </div>
      );
    }
    return c;
  };
  render() {
    const { copyerValue, children, tableKey, onClick, popStyle, align, shouldWrap, ...cellProps } = this.props;
    const { cover, otherProps } = this.getCover({
      ...cellProps,
      copyerValue,
      tableKey,
      onClick,
      popStyle,
      cellStyle: cellProps.style,
      align,
      shouldWrap,
    });
    return (
      <BaseCell {...cellProps} {...otherProps}>
        {children}
        {cover}
      </BaseCell>
    );
  }
}
